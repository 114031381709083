<template>
	<div class="trialdata">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试驾数据"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 车型 -->
		<div class="car__model">
			<img class="car__model__logo" :src="info.car_images" mode="widthFix">
			<div class="car__model__con">{{info.model_name}} {{info.collocation_name}}</div>
		</div>
		
		<!-- 选项 -->
		<div class="opts">
			<div class="opts__title">
				<img src="@/assets/trial/icon_sound.png" style="width: 90px;height: 22px;" mode="">
				<div class="opts__title__con">
					静谧性能
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						placeholder="车外最大噪音值"
						v-model="form.idling_noise"
					></input>
				</div>
				<div class="opt__init">
					请输入60KM/H下，车外的最大噪音数值
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						placeholder="车内最小噪音值"
						v-model="form.down_noise"
					></input>
				</div>
				<div class="opt__init">
					请输入60KM/H下，车内的最小噪音数值
				</div>
			</div>
		</div>
		<div style="margin: 0 auto; width: 335px; height: 1px; background: #333333;"></div>
		
		<!-- 选项 -->
		<div class="opts">
			<div class="opts__title">
				<img src="@/assets/trial/icon_air.png" style="width: 48px;height: 42px;" mode="">
				<div class="opts__title__con">
					空气净化效果
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						placeholder="户外空气指数"
						v-model="form.clean_air"
					/>
				</div>
				<div class="opt__init">
					请输入户外的空气PM2.5指数值
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						placeholder="车内空气指数"
						v-model="form.down_air"
					/>
				</div>
				<div class="opt__init">
					请输入车内的空气最低PM2.5指数值
				</div>
			</div>
		</div>
		
		<!-- 保存 -->
		<div style="height: 72px;"></div>
		<div class="savebtn"  style="height: 71px;"  :style="{'top':(docmHeight - 71) + 'px'}">
			<div @click="handSave">保存</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			info: {
				number: '',
			},
			form: {
				idling_noise: '',
				down_noise: '',
				clean_air: '',
				down_air: '',
			},
			type: 'add'
		};
	},
	mounted() {
		if (this.$route.query.number) this.info.number = this.$route.query.number
		this.getTrialInfo()
		this.getTrialReport()
	},
	computed: {    
			docmHeight() {
				console.log(this.$store.state.height)
				return this.$store.state.height;
			}
		},
	methods: {
		// 保存
		async handSave(e) {
			this.form.number = this.info.number
			const {data: result} = await this.$http.post(this.type == 'add' ? 
				'/api/scsj/create_report_1_5' : '/api/scsj/edit_report_1_5', this.form)
			if (result.code == 1) {
				this.$toast.success('保存成功')
				this.$router.replace({
					path: `/trial-report?number=${this.info.number}`
				})
			}
		},
		
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.car_images = this.serverUrl(this.info.car_images)
			}
		},
		
		// 试驾报告
		async getTrialReport(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_report_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				if (result.data.res.idling_noise) {
					this.type = 'edit'
				}
				this.form.idling_noise = result.data.res.idling_noise
				this.form.down_noise = result.data.res.down_noise
				this.form.clean_air = result.data.res.clean_air
				this.form.down_air = result.data.res.down_air
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.car__model{
	width: 100%;
	height: 136px;
	background: #222;
	display: flex;
	align-items: center;
	justify-content: center;
	&__logo{
		width: 200px;
		margin-right: 40px;
	}
	&__con{
		font-size: 30px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
	}
}
.opts{
	padding: 50px 0 60px;
	text-align: center;
	&__title{
		&__con{
			margin-top: 20px;
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 36px;
		}
	}
	.opt{
		&__input{
			margin: 48px auto 0;
			width: 276px;
			border-bottom: 1px solid #666;
			padding: 12px 0;
			input{
				width: 100%;
				box-sizing: border-box;
				height: 40px;
				font-size: 40px;
				font-family: HYQiHeiY3-45;
				background-color: rgba(1, 1, 1, 0);
				text-align: center;
				border: none;
				color: #fff;
			}
		}
		&__init{
			margin: 24px auto 0;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 36px;
		}
	}
}
.savebtn{
	position: fixed;
	z-index: 1000;
	padding: 0 40px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		text-align: center;
		font-size: 36px;
		height: 91px;
		line-height: 91px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}
</style>
